import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../Login/LoginScreen.css'; 
import { fetchUserdetail , withdrawalrequest} from '../../../api/api';
import Header from '../common/header';
import Container from '../../container';
import { useNavigate } from 'react-router-dom';
import YourMistake from '../yourmistake';
import ToastMsg from '../common/ToastMsg';

const WithdrawAmount = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const initialBalance = queryParams.get('balance') || 0;

    const [amount, setAmount] = useState(initialBalance); 
    const [balance, setBalance] = useState(initialBalance);
    const [upiId, setUpiId] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [platformFeePaid, setPlatformFeePaid] = useState(false); 
    const [noMistake, setNoMistake] = useState(true)
    const [refercount, setReferCount] = useState(0)

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-IN', { 
            style: 'currency', 
            currency: 'INR',
            minimumFractionDigits: 0, 
            maximumFractionDigits: 0  
        }).format(amount);
      };
      useEffect (( )=>{
        setReferCount(parseInt(queryParams.get('refercount'), 10))
        handlefetchUserdetail()
    },[])

      const handlefetchUserdetail = async () => {
        try {
            const data = await fetchUserdetail();
            console.log(data.data.is_faulty, "user API response");
            setNoMistake(data.data.is_faulty)
            setPlatformFeePaid(data.data.platform_fee_paid)
    
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };
    const validateUpiId = (id) => {
        const upiPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+$/;
        return upiPattern.test(id);
    };

    const handleUPInputChange = (e) => {
        const { value } = e.target;
        setUpiId(value); 
        setIsValid(validateUpiId(value));  
    };

    const handleWithdrawalRequest = async (upi_id, amount) => {
        
        const token = localStorage.getItem('access_token');
      
      
        const withdrawalData = {
          upi_id: upi_id,
          amount: parseInt(amount, 10),
          token
        };
      
        try {
          const response = await withdrawalrequest(withdrawalData.upi_id, withdrawalData.amount, withdrawalData.token);
          
          if (response) {
            ToastMsg.success('Withdrawal request submitted successfully!');

                        setTimeout(() => {
            navigate(`/dashboard?keyfrom=${"withdrawal"}`);
            }, 3000);

          } else {
            ToastMsg.error('There was an issue with your withdrawal request. Please try again.');
          }
        } catch (error) {
          console.error('Error submitting withdrawal request:', error);
          ToastMsg.error('An error occurred while processing your request. Please try again.');
        }
      };

      
    const handleSubmit = (e) => {
        const refrcount = refercount;
        
        e.preventDefault(); 
    
        if (upiId.trim() === '' || !validateUpiId(upiId)) {
            setIsValid(false);  
            return; 
        }
    
        setIsValid(true);
        if (!platformFeePaid) {
            navigate(`/platform-fee?amount=${amount}`);
        } else if (refrcount < 3) {
            navigate(`/referandearn?keyfrom=withdrawal`);
        } else if (noMistake){
            navigate(`/your-mistake`);
        }
    
       else
        {

            handleWithdrawalRequest(upiId,amount-1 )        }
    };
    
    
    
    useEffect(() => {
       
        
        setBalance(initialBalance);
    }, [initialBalance]);
    

    const handleSliderChange = (e) => {
        setAmount(e.target.value);
    };

    return (
        <Container>
            <div className="withdraw-container">
                <div className="flex-row mt-10">
                    <Header />
                </div>
                <h2 className="balance-title">Available Balance</h2>
                <p style={{color: "green"}} className="balance-amount">{formatCurrency(balance)}</p>
                <div className="withdraw-section">
                    <h3 className="withdraw-instruction">Select amount you want to withdraw</h3>
                    <div className="withdraw-amount-box">
                        {formatCurrency(amount?.toLocaleString())}
                    </div>
                    <input 
                        type="range" 
                        min="0" 
                        max={balance} 
                        value={amount} 
                        className="withdraw-slider" 
                        onChange={handleSliderChange}
                    />
                    <div className="slider-labels">
                        <span>₹0</span>
                        <span>{formatCurrency(balance)}</span>
                    </div>
                </div>
                <div className="upi-section">
                    <h3 className="upi-title">Enter your UPI ID</h3>
                    <form onSubmit={handleSubmit}>
                        <input 
                            type="text" 
                            placeholder="Your UPI ID" 
                            className={`upi-input ${isValid ? '' : 'invalid'}`}
                            value={upiId}
                            maxLength={50} 
                            onChange={handleUPInputChange}
                        />
                        {!isValid && <p className="error-message">Invalid UPI ID format.</p>}
                    </form>
                    <p className="upi-instruction">Enter Your UPI ID to Receive Amount</p>
                </div>
            </div>
            <button onClick={handleSubmit} className="verify-button">Withdraw Amount</button>
            <p className="safety-note">100% Safe & Secure</p>
        </Container>
    );
};

export default WithdrawAmount;
