import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../components/pages/Login/LoginScreen.css';
import Header from './common/header';
import { fetchIFSCDetails, submitBankDetails, getBankDetails } from '../../api/api';
import ToastMsg from './common/ToastMsg'; 

const AddBankDetails = () => {
  const [accountNumber, setAccountNumber] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [bankName, setBankName] = useState('');
  const [branchName, setBranchName] = useState('');
  const [holderName, setHolderName] = useState('');
  const [ifscValid, setIfscValid] = useState(true);
  const navigate = useNavigate();

  const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
  const maxAccountNumberLength = 16;
  const maxIfscLength = 11;

  const fetchBankDetails = async () => {
    try {
      const token = localStorage.getItem('access_token');

      if (!token) {
        // ToastMsg.error('Token not found. Please log in again.');
        return;
      }

      const response = await getBankDetails(token);
      const bankDetails = response?.data;
// console.log(bankDetails?.data?.holder_name, "response?.data");

      if (bankDetails) {
        setAccountNumber(bankDetails?.data?.acc_no || '');  
        setIfscCode(bankDetails?.data?.ifsc || '');         
        setHolderName(bankDetails?.data?.holder_name || '');
      }

      ToastMsg.success('Bank details fetched successfully!');
    } catch (error) {
      console.error('Error fetching bank details:', error);
      // ToastMsg.error('Failed to fetch bank details. Please try again.');
    }
  };

  useEffect(() => {
    fetchBankDetails(); 
  }, []);

  const handleIFSCChange = async (e) => {
    const value = e.target.value.toUpperCase();
    setIfscCode(value);

    if (ifscRegex.test(value)) {
      setIfscValid(true);

      try {
        const ifscDetails = await fetchIFSCDetails(value);
        setBankName(ifscDetails?.BANK || '');
        setBranchName(ifscDetails?.BRANCH || '');
      } catch (error) {
        console.error('Error fetching IFSC details:', error);
        ToastMsg.error('Invalid IFSC code or bank details not found.');
        setBankName('');
        setBranchName('');
      }
    } else {
      setIfscValid(false);
      setBankName('');
      setBranchName('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!ifscValid) {
        ToastMsg.error('Invalid IFSC code.');
        return;
    }

    if (!accountNumber || !holderName || !ifscCode ) {
        ToastMsg.error('Please fill out all fields.');
        return;
    }

    const bankData = {
        account_number: accountNumber,
        ifsc_code: ifscCode,
        bank_name: bankName, 
        account_holder_name: holderName,
    };

    try {
        const token = localStorage.getItem('access_token');
        
        const response = await submitBankDetails(bankData.account_number, bankData.ifsc_code,  bankData.account_holder_name, token);
        
        if (response.status === 200) {
            ToastMsg.success('Bank details added successfully!');
            navigate('/bank-details-confirmation');
        } else {
            ToastMsg.error(response?.message || 'Failed to add bank details. Please try again.');
        }
    } catch (error) {
        console.error('Error submitting bank details:', error);
        ToastMsg.error('An error occurred while submitting bank details. Please try again.');
    }
  };

  return (
    <div className='pd20'>
      <Header />
      <h2 className="title mb-20">Add Bank Details</h2>
      <form className="form" onSubmit={handleSubmit}>
        <div className="input-wrapper mb-20">
          <input
            type="text"
            placeholder="Enter Account Number"
            className="input-field"
            value={accountNumber}  // Prefill account number from state
            maxLength={maxAccountNumberLength}
            onChange={(e) => setAccountNumber(e.target.value.replace(/\D/g, ''))} 
            required
          />
        </div>
        <div className="input-wrapper mb-20">
          <input
            type="text"
            placeholder="Enter IFSC Code"
            className={`input-field ${ifscValid ? '' : 'input-invalid'}`}
            value={ifscCode}  // Prefill IFSC code from state
            maxLength={maxIfscLength}
            onChange={handleIFSCChange}
            required
          />
          {!ifscValid && <span className="error-message">Invalid IFSC code format</span>}
        </div>
        {bankName && branchName && (
          <div className="bank-details">
            <p>Bank Name: {bankName}</p>
            <p>Branch: {branchName}</p>
          </div>
        )}
        <div className="input-wrapper mb-20">
          <input
            type="text"
            placeholder="Enter Account Holder Name"
            className="input-field"
            value={holderName}  
            onChange={(e) => setHolderName(e.target.value.toUpperCase())} 
            required
          />
        </div>
        <button type="submit" className="verify-button2">Submit</button>
      </form>
    </div>
  );
};

export default AddBankDetails;
