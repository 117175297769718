// src/App.js
import React from 'react';
import AppRoutes from './routes/Routes';
import Container from './components/container';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/App.css';


const App = () => {
  return (
    <div className="App">
        <AppRoutes />
        <ToastContainer />
    </div>
  );
};

export default App;
