import React from 'react';
import '../Login/LoginScreen.css'; 
import Header from '../common/header';
import { useState , useEffect} from 'react';
import profileImage from  '../../../assets/images/userimg.png'; 
import editProfileIcon from '../../../assets/images/edit.png';
import Policies from '../../../assets/images/policies.png';
import editProfile from '../../../assets/images/editprofile.png'; 
import referIcon from '../../../assets/images/referr.png'; 
import withdrawIcon from '../../../assets/images/withdraw.png'; 
import helpIcon from '../../../assets/images/help.png';
import logoutIcon from '../../../assets/images/logout.png'; 
import { useNavigate } from 'react-router-dom';
import Container from '../../container';
import { fetchUserdetail } from '../../../api/api';
import ToastMsg from '../common/ToastMsg.js'; 



const Profile = () => {
    const [name, setname] = useState("User");
    const [eamil, setEamil] = useState("abdf@gmail.com");
    const [phone, setphone] = useState("1234567890");
    const [referral, setReferral] = useState(null);
    const [reaferralCount, setReaferralCount] = useState(0);
    const [point, setPoint] = useState(0);
    
    
    
    
    
    useEffect (( )=>{
        handlefetchUserdetail()
    },[])

    const handlehelpcenter = () =>{
      navigate('/help-center');
    }
    const handlewithdrawalearning = () => {
      if (point >= 500) {
        navigate(`/amount-withdraw?balance=${point}`);
      } else {
        ToastMsg.error('You need a minimum balance of ₹500 to withdraw earnings.');
      }
    };
    const handleEditProfile = () =>{
      navigate(`/edit-profile`);
    }

    const handleAddBankDetails = () =>{
      navigate(`/add-bank-details`);
    }

    const handlePolicies =()=>{
      navigate(`/privacy`)
    }
    
    const handleRefernearn = () => {
      if (referral) {
        navigate(`/referandearn?referralId=${referral}&referralCount=${reaferralCount}`);

      } else {
        navigate('/referandearn');
      }
    };
    const handlefetchUserdetail = async () => {
      try {
          const data = await fetchUserdetail();
          console.log(data, "user API response");
  
          setname(data?.data?.first_name);
          setEamil(data?.data?.email);
          setphone(data?.data?.phone_number);
          setPoint(data?.data?.points);
          setReferral(data?.data?.referral_id);
          setReaferralCount(data?.data?.referral_count ?? 0);
  
      } catch (error) {
          console.error('Error fetching user details:', error);
          ToastMsg.error('Error fetching user details. Please try again.'); 
      }
  };
  
      const goBack = () => {
        navigate(-1);
    };

        const navigate = useNavigate();
      
        const handleLogout = () => {
          localStorage.clear();
          navigate('/');
        };
    return (
        <Container>
        <div className="profile-container">
            <div className="flex-row mt-10">
                <Header />
            </div>
            <img src={profileImage} alt="Profile" className="profile-image" />
            <h2 className="profile-name">{name}</h2>
            <p className="profile-email">{eamil}</p>
            <p className="profile-phone">+91 {phone}</p>
            <p className="profile-phone"> your refer id is : {referral}</p>
            <p className="profile-phone"> your refer count is: {reaferralCount}</p>
            
            <div className="profile-options">
  <div onClick={handleEditProfile} className="profile-option">
    <img src={editProfile} alt="Edit Profile" className="option-icon" />
    <p className="option-text">Edit Profile</p>
  </div>
  <div onClick={handleAddBankDetails} className="profile-option">
    <img src={editProfileIcon} alt="Edit Profile" className="option-icon" />
    <p className="option-text">Add Bank Details</p>
  </div>
  <div onClick={handlePolicies} className="profile-option">
    <img src={Policies} alt="Edit Profile" className="option-icon" />
    <p className="option-text">Policies</p>
  </div>
  <div onClick={handleRefernearn} className="profile-option">
    <img src={referIcon} alt="Refer And Earn" className="option-icon" />
    <p className="option-text">Refer And Earn</p>
  </div>
  <div onClick={handlewithdrawalearning} className="profile-option">
    <img src={withdrawIcon} alt="Withdraw Earnings" className="option-icon" />
    <p className="option-text">Withdraw Earnings</p>
  </div>
  <div onClick={handlehelpcenter} className="profile-option">
    <img src={helpIcon} alt="Help Center" className="option-icon" />
    <p className="option-text">Help Center</p>
  </div>
  <div onClick={handleLogout} className="profile-option">
    <img src={logoutIcon} alt="Log out" className="option-icon" />
    <p className="option-text">Log out</p>
  </div>
</div>

        </div>
      <div className='button-div'>
      <button onClick={goBack} className="go-back-button">Go Back</button>
      </div>
        </Container>
    );
};

export default Profile;
