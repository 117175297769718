import React from 'react';
import '../../components/pages/Login/LoginScreen.css';
import Header from '../../components/pages/common/header';
import Container from '../container';
import { useNavigate } from 'react-router-dom';

const YourMistake = () => {
    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/dashboard');
    };

    return (
        <Container>
            <Header />
            <div className="your-mistake-container">
                <h1 className="your-mistake-title">Your Mistakes in Withdrawal</h1>
                <p className="your-mistake-description">
                    We noticed a few issues that may have affected your ability to withdraw funds. Please review the following points:
                </p>
                <div className="mistake-list">
                    <div className="mistake-item">
                        <h2 className="mistake-heading">1. Video Skipping</h2>
                        <p className="mistake-content">
                            It seems you have skipped some videos. Please ensure you watch the required content fully to become eligible for withdrawals.
                        </p>
                    </div>
                    <div className="mistake-item">
                        <h2 className="mistake-heading">2. Automation or Bots Detected</h2>
                        <p className="mistake-content">
                            Our system detected suspicious automation behavior. Make sure to interact with the platform naturally.
                        </p>
                    </div>
                    <div className="mistake-item">
                        <h2 className="mistake-heading">3. Irregular Video Watching</h2>
                        <p className="mistake-content">
                            You watched the videos irregularly. To maintain eligibility, please watch at least 60 videos daily as per our policies.
                        </p>
                    </div>
                    <div className="mistake-item">
                        <h2 className="mistake-heading">4. Terms & Conditions</h2>
                        <p className="mistake-content">
                            Kindly review our Terms & Conditions and Privacy Policy to avoid such issues in the future.
                        </p>
                    </div>
                </div>
                <p className="reminder">
                    Remember, the platform fee you paid will be returned in your withdrawal amount!
                </p>
                <h2 className="conclusion-heading">Conclusion</h2>
                <p className="conclusion-content">
                    To be eligible for withdrawals, please adhere to the guidelines above. We are here to help you earn more!
                </p>
                <button className="keep-watching-button" onClick={handleRedirect}>
                    Keep Watching Videos and Start Earning
                </button>
            </div>
        </Container>
    );
};

export default YourMistake;
