import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { load } from '@cashfreepayments/cashfree-js'; 
import '../Login/LoginScreen.css'; 
import Header from '../common/header';
import PlatformFeesicon from '../../../assets/images/platformimg.png'; 
import Container from '../../container';
import { createOrder, getUpiApi } from '../../../api/api';  
import ToastMsg from '../common/ToastMsg.js';  

const PlatformFees = () => {
    const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
    const [withdrawalAmount, setWithdrawalAmount] = useState(null);
    const [cashfree, setCashfree] = useState(null);

    const location = useLocation();

    
    useEffect(() => {
        load({ mode: "production" })
            .then((cashfreeInstance) => {
                setCashfree(cashfreeInstance); 
            })
            .catch((error) => {
                console.error("Error loading Cashfree SDK:", error);
            });
    }, []);

  
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const amountFromQuery = queryParams.get('amount');

        if (amountFromQuery) {
            localStorage.setItem('withdrawal_amount', amountFromQuery);  
            setWithdrawalAmount(amountFromQuery);  
        }
    }, [location]);

    const createOrderApiFunction = (depositAmount, token) => {
        createOrder(depositAmount, null, token)
            .then((data) => {
                const paymentSessionId = data?.data?.payment_session_id;
                console.log('Order created successfully:', paymentSessionId);
                const url = `/m-payment?deposit=${encodeURIComponent(depositAmount)}&KeyFrom=${encodeURIComponent("Platform Fee")}`;
                // const url = `/m-payment`;
                window.location.href = url;
                // if (paymentSessionId) {
                //     const handlePayment = () => {
                //         if (cashfree) {
                //             const checkoutOptions = {
                //                 paymentSessionId: paymentSessionId,
                //                 redirectTarget: "_self" 
                //             };

                //             cashfree.checkout(checkoutOptions)
                //                 .then((result) => {
                //                     if (result.error) {
                //                         console.log("Payment error: ", result.error);
                //                     } else if (result.paymentDetails) {
                //                         console.log("Payment completed: ", result.paymentDetails.paymentMessage);
                //                         ToastMsg.success('Payment completed successfully!');
                //                     }
                //                 });
                //         } else {
                //             console.log("Cashfree SDK not initialized yet");
                //         }
                //     };

                //     handlePayment();
                // } else {
                //     ToastMsg.error('Failed to retrieve payment session ID.');
                // }
            })
            .catch((error) => {
                console.error('Error creating order:', error);
                ToastMsg.error('Error processing payment. Please try again.');
                setIsPaymentProcessing(false);
            });
    };

    const handlePaymentClick = () => {
        setIsPaymentProcessing(true);  
        const depositAmount = 499;  
        const token = localStorage.getItem('access_token'); 

        createOrderApiFunction(depositAmount, token);
        handleConfirmManually()
    };

    const handleConfirmManually = async () => {
        try {
          const data = await getUpiApi();
          console.log(data, "UPI API response");
          

      
        } catch (error) {
          console.error('Error fetching UPI details:', error);
          ToastMsg.error('Error fetching UPI details. Please try again.');
        }
      };
    return (
        <Container>
            <div className="platform-fees-container">
                <div className="flex-row mt-10">
                    <Header />
                </div>
                <h2 className="verify-titlee">Pay Platform Fee</h2>
                <div className="instructions-container">
                    <p className="verify-instructions">
                        To proceed with the withdrawal of your funds, you are required to pay a one-time platform fee of <br/> <br/> <span onClick={handlePaymentClick} className='platformamount'>₹499/-</span> .
                    </p>
                    <p className="verify-instructions">
                        Once you have paid the fee, you will be able to withdraw your available balance directly to your account.
                    </p>
                </div>
                <div className="payment-container">
                    <div onClick={handlePaymentClick} className="payment-methods">
                        <img src={PlatformFeesicon} alt="platformfee" className="payment-icon" />
                    </div>
                </div>
            </div>

            <button 
                className="verify-button" 
                onClick={handlePaymentClick}
                disabled={isPaymentProcessing} 
            >
                {isPaymentProcessing ? 'Processing...' : 'Pay ₹499'}
            </button>
            <p className="safety-note">Once the payment is completed, you will be able to withdraw your funds instantly.</p>
        </Container>
    );
};

export default PlatformFees;
