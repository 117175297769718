import React, { useState } from 'react';
import '../Login/LoginScreen.css';  
import Header from '../common/header';
import lock from '../../../assets/images/lock.png';
import eyeoff from '../../../assets/images/eye-off.png';
import eyeon from '../../../assets/images/eye-on.png';
import { useNavigate } from 'react-router-dom';
import Container from '../../container';
import {setNewPassword} from '../../../api/api'
import { useLocation } from 'react-router-dom';
import ToastMsg from '../common/ToastMsg.js'; 

const CreateNewPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const email = queryParams.get('email');

    const handleSetNewPassword = async (email, confirmPassword) => {
        const useremail = email;  
        const newPassword = confirmPassword; 
        
        try {
            const response = await setNewPassword(useremail, newPassword);
            console.log('Password Change Success:', response);
            ToastMsg.success('Password changed successfully!');
            navigate('/loginn');
        } catch (error) {
            console.error('Password Change Failed:', error);
            ToastMsg.error('Failed to change password. Please try again.'); 
        }
    };
    

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password.length < 4) {
            setError('Password must contain 4 digit only');
        } else if (password !== confirmPassword) {
            setError('Passwords do not match');
        } else {
            setError('');
            // Add password reset logic here
            console.log('Password reset successful');
            handleSetNewPassword(email, confirmPassword)
            
        }
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };

    return (
        <Container>
        <div className="verify-account-container">
        <div
  className='flex-row mt-10'
  onClick={() => {
    localStorage.removeItem('access_token');
    console.log('Token removed');
  }}
>
  <Header />
</div>
            <h2 className="verify-title">Create New Password</h2>
            <p className="verify-instructions">
                Please Enter And Confirm Your New Password
            </p>
            <div className="input-container">
                <img src={lock} alt="Lock Icon" className="icon-size" />
                <input 
                    type={passwordVisible ? "text" : "password"}
                    placeholder="Enter your password" 
                    className="input-field" 
                    value={password} 
                    onChange={handlePasswordChange} 
                    maxLength={4}
                />
                <img
                    src={passwordVisible ? eyeon : eyeoff}
                    alt="Toggle Visibility"
                    className="visibility-icon icon-size"
                    onClick={togglePasswordVisibility}
                />
            </div>
            <p className="verify-instructions passmustcontainchar">Must contain 4 digit</p>
            <div className="input-container">
                <img src={lock} alt="Lock Icon" className="icon-size" />
                <input 
                    type={confirmPasswordVisible ? "text" : "password"}
                    placeholder="Confirm your password" 
                    className="input-field" 
                    value={confirmPassword} 
                    onChange={handleConfirmPasswordChange} 
                    maxLength={4}
                />
                <img
                    src={confirmPasswordVisible ? eyeon : eyeoff}
                    alt="Toggle Visibility"
                    className="visibility-icon icon-size"
                    onClick={toggleConfirmPasswordVisibility}
                />
            </div>
            {error && <p className="error-message">{error}</p>}
            <button className="verify-button" onClick={handleSubmit}>Reset Password</button>
        </div>
        </Container>
    );
};

export default CreateNewPassword;
