import React, { useState, useEffect } from 'react';
import '../Login/LoginScreen.css';
import Header from '../common/header';
import lock from '../../../assets/images/lock.png';
import { useNavigate } from 'react-router-dom';
import Container from '../../container';
import { useLocation } from 'react-router-dom';
import { verifyOtp, forgetPassword } from '../../../api/api';  
import ToastMsg from '../common/ToastMsg.js'; 

const VerifyAccount = () => {
  const [code, setCode] = useState('');
  const [validationMessage, setValidationMessage] = useState('');
  const [timer, setTimer] = useState(10);
  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const [error, setError] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');

  // Timer logic
  useEffect(() => {
    if (timer === 0) {
      setIsResendEnabled(true);
      return;
    }

    const intervalId = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timer]);

  const handleChange = (e) => {
    const value = e.target.value;

    if (/^\d{0,4}$/.test(value)) {
      setCode(value);
      setValidationMessage('');
      setError(false);
    } else {
      setValidationMessage('Max OTP length is 4 digits.');
    }
  };

  const handleNavigation = () => {
    handleVerifyOtp(email, code);
  };

  const handleResend = async () => {
    try {
        const response = await forgetPassword(email);
        console.log(response, "Resend OTP response");
        
        setTimer(59);
        setIsResendEnabled(false);
        ToastMsg.success('OTP has been resent successfully!'); 
    } catch (error) {
        console.error('Error resending OTP:', error);
        ToastMsg.error('Error resending OTP. Please try again.'); 
    }
};


  const handleVerifyOtp = async (email, code) => {
    const otp = code; 
    const useremail = email; 
    
    try {
      const response = await verifyOtp(otp, useremail);
      console.log('OTP Verification Success:', response);
      ToastMsg.success('OTP verified successfully!'); 
      navigate(`/update-password?email=${encodeURIComponent(email)}`);
    } catch (error) {
      console.error('OTP Verification Failed:', error);
      ToastMsg.error('OTP verification failed. Please try again.'); 
      setError(true);
    }
};


  return (
    <Container>
      <div className="verify-account-container">
        <div className='flex-row mt-10'>
          <Header />
        </div>
        <h2 className="verify-title">Verify Account</h2>
        <p className="verify-instructions">
          Code has been sent to <span className="email-highlight">{email}</span>.<br />
          Enter the code to verify your account.
        </p>
        <div className="input-container">
          <img src={lock} alt="Lock Icon" className="icon-size" />
          <input
            type="text"
            placeholder="4 digit code"
            className="input-field"
            value={code}
            onChange={handleChange}
          />
        </div>
        {validationMessage && <p style={{ color: 'red' }}>{validationMessage}</p>}
        <p className="resend-text">
          Didn’t Receive Code?{' '}
          <a
            className={`colournone font-500 underline ${isResendEnabled ? '' : 'disabled'}`}
            onClick={isResendEnabled ? handleResend : (e) => e.preventDefault()}
          >
            Resend Code
          </a>
        </p>
        <p className="resend-text">
          Resend code in 00:{timer < 10 ? `0${timer}` : timer}
        </p>
        {error && <p style={{ color: 'red' }}>Enter Correct OTP</p>}
        <button onClick={handleNavigation} className="verify-button">Verify Account</button>
      </div>
    </Container>
  );
};

export default VerifyAccount;
