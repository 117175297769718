import React from 'react';
import '../Login/LoginScreen.css'; 
import Header from '../common/header';
import errorIcon from '../../../assets/images/error.png'; 
import Container from '../../container';

const PoliciesScreen = () => {
    const handleWatchVideo = () => {
        window.location.href = "/dashboard";
    }

    return (
        <Container>
            <div className="oops-container">
                <div className="flex-row mt-10">
                    <Header />
                </div>
                <div className="error-icon-container mt-20">
                    {/* <img src={errorIcon} alt="error" className="error-icon" /> */}
                </div>

                <h2 className="terms-title" style={{ fontSize: "28px", color: "#333", textAlign: "center", marginBottom: "20px", textTransform: "uppercase", letterSpacing: "1px" }}>Privacy Policy</h2>
                
                <div className="instructions-container" style={{ padding: "15px", backgroundColor: "#f9f9f9", borderRadius: "10px", boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)" }}>
                    <p className="terms-instructions" style={{ fontSize: "16px", color: "#444", lineHeight: "1.8", marginBottom: "15px" }}>
                        <strong style={{ color: "#333", fontSize: "17px" }}>Data Collection:</strong> PaiseBnao collects personal information such as name, email, and phone number during registration to enhance user experience. We may also collect data about your activity within the app for analytical purposes.
                    </p>
                    <p className="terms-instructions" style={{ fontSize: "16px", color: "#444", lineHeight: "1.8", marginBottom: "15px" }}>
                        <strong style={{ color: "#333", fontSize: "17px" }}>Data Usage:</strong> The information collected is used to personalize content, improve services, and process payments. We do not share your personal data with third parties, except for payment processing and legal compliance.
                    </p>
                    <p className="terms-instructions" style={{ fontSize: "16px", color: "#444", lineHeight: "1.8", marginBottom: "15px" }}>
                        <strong style={{ color: "#333", fontSize: "17px" }}>Cookies and Tracking:</strong> PaiseBnao may use cookies to track user activity and preferences. This helps us optimize the platform experience, but users can choose to disable cookies in their browser settings.
                    </p>
                    <p className="terms-instructions" style={{ fontSize: "16px", color: "#444", lineHeight: "1.8", marginBottom: "15px" }}>
                        <strong style={{ color: "#333", fontSize: "17px" }}>Security Measures:</strong> We implement industry-standard security protocols to protect your data from unauthorized access. However, users are responsible for safeguarding their login credentials.
                    </p>
                    <p className="terms-instructions" style={{ fontSize: "16px", color: "#444", lineHeight: "1.8", marginBottom: "15px" }}>
                        <strong style={{ color: "#333", fontSize: "17px" }}>Data Retention:</strong> We retain personal data for as long as necessary to provide our services or as required by law. Users can request the deletion of their data by contacting support.
                    </p>
                    <p className="terms-instructions" style={{ fontSize: "16px", color: "#444", lineHeight: "1.8", marginBottom: "15px" }}>
                        <strong style={{ color: "#333", fontSize: "17px" }}>Changes to Policy:</strong> PaiseBnao reserves the right to modify this privacy policy at any time. Users will be notified of significant changes, and continued use of the app constitutes acceptance of the updated policy.
                    </p>
                    <p className="terms-instructions" style={{ fontSize: "16px", color: "#444", lineHeight: "1.8", marginBottom: "15px" }}>
                        <strong style={{ color: "#333", fontSize: "17px" }}>Conversion of Earnings:</strong> PaiseBnao reserves the right to convert rupees earned by users into points at any time. This means that users' rupees may be converted into a points-based system at the discretion of the company. The conversion rate and the way these points can be redeemed will be communicated when necessary. Users must acknowledge that the value of points may vary and agree to these terms by continuing to use the platform.
                    </p>
                    <p style={{ fontSize: "15px", color: "#555", lineHeight: "1.6", textAlign: "justify" }}>
                        The Company will try its best to take all the data provided by the User, but if the company fails to keep the user's data safe due to any technical issue or any other reason then the user will not be able to claim against the Company.
                        <br />
                        <br />
                        User please make sure that if he shares his data with the company and if the company uses this for its own purchase or legal reasons then the company will not take any responsibility in this, the user will be responsible himself for this.
                    </p>
                </div>

                   
            </div>
    
            <div onClick={handleWatchVideo} className="verify-button2" style={{ marginTop: "30px", padding: "15px 30px", backgroundColor: "#28a745", color: "#fff", textAlign: "center", borderRadius: "8px", cursor: "pointer", fontSize: "16px", fontWeight: "bold" }}>
                Continue Watching Videos
            </div>
        </Container>
    );
};

export default PoliciesScreen;
