import React, { useState, useEffect } from 'react';
import '../../pages/Login/LoginScreen.css';
import Header from '../common/header';

const PaymentFailure = ({ transactionNumber, amountPaid, paymentMethod, onRetry }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    
    const fetchTransactionDetails = () => {
      try {
        
        setTimeout(() => {
          setLoading(false);  
        }, 2000);
      } catch (error) {
        setLoading(false);
        setError('Failed to load transaction details. Please try again.');
      }
    };

    fetchTransactionDetails();
  }, []);

  const handleRetryClick = () => {
    if (onRetry) {
      onRetry();  
    } else {
      alert('Retry payment initiated!');
    }
  };

  if (loading) {
    return (
      <div className="payment-failure-container">
        <div className="loading">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="payment-failure-container">
        <div className="error-message">{error}</div>
      </div>
    );
  }

  return (
    <div className="payment-failure-container">
        <Header />
      <div className="failure-icon">
        <div className="circle">
          <div className="crossmark">✘</div>
        </div>
      </div>
      <h2>Payment Failed</h2>
      <p>Transaction Number: {transactionNumber}</p>
      <div className="payment-details">
        <p>Amount attempted <span className="amount">₹{amountPaid}</span></p>
        <p>Payed via <span className="payment-method">{paymentMethod}</span></p>
      </div>
      <button className="retry-button" onClick={handleRetryClick}>
        Retry Payment
      </button>
    </div>
  );
};

export default PaymentFailure;
