import React from 'react';
import { useState } from 'react';
import './index.css';
import paisebnaotext from '../../assets/images/paisebnaotext.png'
import demoWebImg from "../../assets/images/demoimg.png"
import playstorebtn from "../../assets/images/GooglePlay.png"
import watchVideo from "../../assets/images/player.png"
import refer from "../../assets/images/earnmoney.png"
import earn from "../../assets/images/earned.png"
import GetStartedNow from "../../assets/images/GetStartedNow.png"
import MrManoj from "../../assets/images/Mrmanoj.jpg"
import { useNavigate } from 'react-router-dom';
import paisebnaoQr from "../../assets/images/paisebnaoQr.png"
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ReactPlayer from 'react-player';
import {ContactUs} from "../../api/api"
import ToastMsg from './common/ToastMsg';

function App() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleVideoReady = () => {
    setIsLoading(false);
  };

  const handleContactUs = async (e) => {
    e.preventDefault();

    if (!name || !email || !message) {
      ToastMsg.error('Please fill out all fields.');
      return;
    }

    const contactData = {
      name: name,
      email: email,
      message: message
    };

    try {
      const response = await ContactUs(contactData.message, contactData.name, contactData.email);
      
      if (response?.success) {
        ToastMsg.success('Message sent successfully!');
      } else {
        ToastMsg.success('Thank you for your submission! Our team will reach out to you shortly.');

      }
    } catch (error) {
      console.error('Error submitting contact details:', error);
      ToastMsg.error('An error occurred while sending your message. Please try again.');
    }
  };

 
  
  const navigate = useNavigate();
  
  const handleRedirectRegister = () => {
    navigate('/register');
};

  const handleRedirect = () => {
    navigate('/signup');
};
const toggleMenu = () => {
  setIsDropdownOpen(!isDropdownOpen);
};
  return (
    <div className="App">


<header className="header">
        <img src={paisebnaotext} alt="paisebnaotextlogo" className="smalltextlogo ml-7 mr-2" />
        <nav className="nav">
          <a onClick={handleRedirectRegister}>Register</a>
          <a onClick={handleRedirect} href="#login">Login</a>
          {/* <button className="download-btn">Download App</button> */}
        </nav>
        {/* <div className="menu-icon" onClick={toggleMenu}>
          &#9776; 
        </div> */}
      </header>
      {/* <div className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
      <a onClick={handleRedirectRegister}>Register</a>
        <a onClick={handleRedirect} href="#login">Login</a>
        <button className="download-btn">Download App</button>
      </div> */}
      <section className="hero">
      <h1>Watch Videos, Earn Money</h1>
      <p>
        Discover a new way to earn while you relax. Watch engaging videos and get paid instantly.
        Join our community today and turn your screen time into cash. It's fun, easy, and rewarding!
      </p>
      <div className="video-container">
        <div className="video-player-wrapper">
          {isLoading && <div className="skeleton-loader"></div>}
          <div className={`video-player-index ${isLoading ? 'hidden' : 'fade-in'}`}>
            <ReactPlayer
              url="https://www.youtube.com/watch?v=u2NuUWuwPCM&ab_channel=ParamountPictures"
              controls={true}
              playbackRate={1}
              width="100%"
              height="100%"
              onReady={handleVideoReady}
            />
          </div>
        </div>
      </div>
      <br />
      <img onClick={handleRedirect} className="getStartedNow" src={GetStartedNow} />
    </section>

    
      <section className="how-to-earn">
        <h2>How to Earn?</h2>
        <div className="earn-options">
          <div className="option">
            <img className='refernearn' src={watchVideo} alt="Watch Videos" />
            <h3>Watch Videos</h3>
            <p>Commercial ads, movies, and TV shows online. It's the perfect way to earn money.</p>
          </div>
          <div className="option">
            <img className='refernearn' src={refer}  alt="Refer Your Friends" />
            <h3>Refer Your Friends</h3>
            <p>We’ll confirm as soon as your friend joins. You get ₹500 and your friend gets ₹500.</p>
          </div>
          <div className="option">
            <img className='refernearn' src={earn}  alt="Get Paid" />
            <h3>Get Paid</h3>
            <p>You can transfer it to your Paytm, Phonepe, and more options.</p>
          </div>
        </div>
      </section>
      <section className="about-us">
  <h2>About Us</h2>
  <div className="about-content">
    {/* <img className='Mrmanoj' src={MrManoj} alt="Mr Manoj Hunidwal" /> */}
    <div className='details-container'>
      <p>
      Paisebnao is a simple and fun way to earn money by watching videos. Founded by Mr. Vijay & Mr. Manoj, Paisebnao rewards users for their time and attention with real cash for every video they watch. Start earning today while enjoying engaging content!
      </p>
      <div className="contact-info mrmanojsection">
        <img src={paisebnaoQr} alt="QR Code" className="qr-code mb-20" />
        <div className="contact-details">
          {/* <span className='mrmaojfontcolour'>Mr Vijay Singh</span> */}
          {/* <span className='mrmaojfontcolour'>Mr Manoj Rao</span> */}
          <p>Email: paisebnao1111@gmail.com</p>
          {/* <p>Phone: (+91) 9769335392</p> */}
        </div>
      </div>
    </div>
  </div>
</section>
      <section className="every-minute-counts">
        <div>
          <h2>Every minute counts</h2>
        <p>With PaiseBnao, you can earn rewards just by watching videos. It's simple, fun, and rewarding.</p>
        

          <img className="google-play-btn" src={playstorebtn} />
        
        </div>
        <img className='demoWebImg' src={demoWebImg} />
      </section>


      {/* <section className="contact-us">
      <h2>Contact Us</h2>
      <form className="contact-form" onSubmit={handleContactUs}>
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="email"
          name="email"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <textarea
          name="message"
          placeholder="Your Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        <button type="submit">Send</button>
      </form>


      <div className="contact-details">
        <p>PaiseBnao pvt.ltd Gurugaon</p>
        <p>Email: paisebnao1111@gmail.com</p>

        <div className='maxwidth567'>
          <div style={{ position: 'relative', width: '100%', paddingBottom: '56.25%', height: 0 }}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224346.1409021248!2d76.85626542773614!3d28.42318791829747!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d18a89d2198fb%3A0x8a0932e528a6f3ad!2sGurugram%2C%20Haryana%2C%20India!5e0!3m2!1sen!2sus!4v1622995223932!5m2!1sen!2sus"
              title="Gurgaon Map"
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </section> */}


      
      <footer className="footer">
  <div className="footer-container">
    <div className="logofootersection">
      <img src={paisebnaotext} alt="paisebnaotextlogo" className="smalltextlogo" />
      <p>Paisebnao is an innovative platform that transforms video watching into a rewarding experience. Founded by Manoj, the app leverages advanced algorithms to track user engagement and seamlessly reward users with real cash for each video viewed. Paisebnao ensures a smooth, user-friendly experience, offering a diverse range of engaging content while providing a transparent and instant reward system. By blending entertainment with financial incentives, Paisebnao offers a unique opportunity to monetize your downtime. Start earning today by simply watching videos!

      </p>
    </div>

    <div className="footer-links">
      <h5>Quick Links</h5>
      <span><a className='tclink' href="/termsandcondition">Terms and conditions</a></span>
      <span><a href="/help-center" className='tclink'>Help page</a></span>
      <span><a href="/privacy" className='tclink'>Privacy Policies</a></span>

      

    </div>

    <div className="footer-content">
      <p>&copy; 2024 PaiseBnao</p>
      <p>
        Maintained by <strong><a href="https://www.yarsicorp.com" target="_blank" rel="noopener noreferrer">Yarsicorp Tech Services</a></strong>, your trusted partner in technology solutions.
      </p>
    </div>
  </div>
</footer>
    </div>
  );
}

function handlePlayVideo() {
  // Logic to handle video playing
  alert('Playing Video!');
}

export default App;
