import React, { useState } from 'react';
import '../../components/pages/Login/LoginScreen.css';
import Header from '../../components/pages/common/header';
import Container from '../container';
import { editProfileContainer } from "../../api/api"; 
import ToastMsg from './common/ToastMsg';
import { useNavigate } from 'react-router-dom';

const EditProfileScreen = () => {
    const navigate = useNavigate();
    const [profileData, setProfileData] = useState({
        firstName: '',
        phone: ''
    });
    const [errors, setErrors] = useState({
        firstName: '',
        phone: ''
    });

    const validateName = (name) => {
        const nameRegex = /^[a-zA-Z\s]{3,30}$/;
        return nameRegex.test(name);
    };

    const validatePhone = (phone) => {
        const phoneRegex = /^[6-9]\d{9}$/;
        return phoneRegex.test(phone);
    };
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProfileData({ ...profileData, [name]: value });
    
        if (name === 'firstName') {
            setErrors({ ...errors, firstName: validateName(value) ? '' : 'Invalid name. Only alphabets allowed, 3-30 characters.' });
        } else if (name === 'phone') {
            setErrors({ ...errors, phone: validatePhone(value) ? '' : 'Invalid phone number. Must be 10 digits starting with 6-9.' });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        
        if (!validateName(profileData.firstName)) {
            setErrors({ ...errors, firstName: 'Invalid name. Only alphabets allowed, 3-30 characters.' });
            return;
        }

        if (!validatePhone(profileData.phone)) {
            setErrors({ ...errors, phone: 'Invalid phone number. Must be 10 digits starting with 6-9.' });
            return;
        }

        const token = localStorage.getItem('access_token');

        try {
            const response = await editProfileContainer(profileData.firstName, profileData.phone, token);
            console.log('Profile updated:', response);
            ToastMsg.success('Profile updated successfully!'); 
            navigate('/dashboard');
        } catch (error) {
            console.error('Error updating profile:', error);
            ToastMsg.error(error.response.data.message); 
        }
    };

    return (
        <Container>
            <div style={{width: "100%"}} className="edit-profile-container">
                <div className="flex-row mt-10">
                    <Header />
                </div>
                <h2 className="edit-profile-title">Edit Profile</h2>
                <form className="edit-profile-form mr-20" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="firstName">First Name</label>
                        <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            placeholder="Enter Name"
                            value={profileData.firstName}
                            onChange={handleInputChange}
                            maxLength={30}
                            required
                        />
                        {errors.firstName && <span className="error-message">{errors.firstName}</span>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="phone">Phone</label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            placeholder="Enter Phone"
                            value={profileData.phone}
                            onChange={handleInputChange}
                            maxLength={10}
                            required
                        />
                        {errors.phone && <span className="error-message">{errors.phone}</span>}
                    </div>
                    <button type="submit" className="verify-button2">Save Changes</button>
                </form>
            </div>
        </Container>
    );
};

export default EditProfileScreen;
