import React, { useEffect, useState } from 'react';
import qrImage from '../../assets/images/UpiQr.png';
import Header from '../../components/pages/common/header';
import '../../components/pages/Login/LoginScreen.css';

const Payment = () => {
    const [deposit, setDeposit] = useState(null);
    const [membershipPlanId, setMembershipPlanId] = useState(null);
    const [keyFrom, setKeyFrom] = useState(null);
    const upiId = "manojhudinwal19921@ybl";
    const [copySuccess, setCopySuccess] = useState("");

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        setDeposit(params.get('deposit'));
        setMembershipPlanId(params.get('membership_plan_id'));
        setKeyFrom(params.get('KeyFrom'));
    }, []);

    const handleCopyUPI = () => {
        navigator.clipboard.writeText(upiId)
            .then(() => {
                setCopySuccess("UPI ID copied!");
                setTimeout(() => setCopySuccess(""), 2000); 
            })
            .catch(err => console.error("Failed to copy UPI ID:", err));
    };

    const handleDownloadQR = () => {
        const link = document.createElement('a');
        link.href = qrImage; 
        link.download = 'UPI_QR_Code.png'; 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleShareQR = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'UPI QR Code',
                    text: 'Scan this QR code to make a payment.',
                    files: [
                        new File([await fetch(qrImage).then(res => res.blob())], 'UPI_QR_Code.png', { type: 'image/png' })
                    ]
                });
            } catch (error) {
                console.error("Error sharing QR code:", error);
            }
        } else {
            alert('Sharing is not supported on this device. You can download and share manually.');
        }
    };

    return (
        <div className="payment-container">
            <Header />
            <h2>Receive Money</h2>
            <p>From any UPI app (PhonePe, BHIM, GPay, Paytm)</p>

            <div className="qr-section">
                <img src={qrImage} alt="UPI QR Code" className="qr-image" />
            </div>

            <div className="upi-info">
                <p className="upi-label">UPI ID <span style={{ fontSize: '14px', color: 'gray' }}>(Tap below to copy)</span>:</p>
                <p className="upi-id" onClick={handleCopyUPI} style={{ cursor: 'pointer', color: 'blue' }}>
                    {upiId} <span style={{ fontSize: '14px', color: 'green' }}>{copySuccess}</span>
                </p>
            </div>

            {keyFrom === "Platform Fee" ? (
                <div className="payment-details">
                    <p><strong>Amount to Pay (Platform Fee):</strong> ₹{deposit}</p>
                    <p>This is a one-time refundable platform fee.</p>
                </div>
            ) : (
                deposit && membershipPlanId && (
                    <div className="payment-details">
                        <p><strong>Amount to Pay:</strong> ₹{deposit}</p>
                        <p><strong>Membership Plan ID:</strong> {membershipPlanId}</p>
                    </div>
                )
            )}

            <div className="disclaimer">
                <p>Note: You can take a screenshot of this page and make the payment from your preferred UPI app.</p>
            </div>

            <div className="action-buttons">
                <button className="share-button" onClick={handleShareQR}>Share QR</button>
                <button className="download-button" onClick={handleDownloadQR}>Download QR</button>
            </div>
        </div>
    );
};

export default Payment;
