import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../components/pages/Login/LoginScreen.css'; 
import Header from './common/header';

const BankDetailsConfirmation = () => {
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate('/dashboard'); 
  };

  return (
    <div>
      <Header />
      <div className="confirmation-wrapper">
        <h2 className="title mb-20">Bank Details Added Successfully!</h2>
        <p>Your bank details have been successfully saved. You can now proceed with transactions.</p>
        
        <div className="confirmation-actions">
          <button 
            className="submit-button" 
            onClick={handleBackToHome}
          >
            Back to Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default BankDetailsConfirmation;
