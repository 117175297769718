import React, { useState, useEffect } from 'react';
import '../Login/LoginScreen.css';
import Header from '../common/header';
import { useNavigate, useLocation } from 'react-router-dom';
import emailicon from '../../../assets/images/email.png';
import Container from '../../container';
import { forgetPassword } from "../../../api/api";
import ToastMsg from '../common/ToastMsg'; 

const VerifyAccount = () => {
  const [email, setEmail] = useState('');
  const [validationMessage, setValidationMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hotemailemail = queryParams.get('hotemailemail');

  
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    if (newEmail.length > 154) {
      setValidationMessage('Email must be 154 characters or less.');
    } else {
      setEmail(newEmail);
      setValidationMessage(''); 
    }
  };

  
  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(email)) {
      setValidationMessage('Please enter a valid email address.');
      return false;
    }
    return true;
  };

  // Automatically send verification code if 'hotemailemail' is present
  useEffect(() => {
    if (hotemailemail) {
      setEmail(hotemailemail);  // Set the email to 'hotemailemail'
      handleForgetPassword(hotemailemail); // Automatically send verification code
    }
  }, [hotemailemail]);

  // Handle forget password request
  const handleForgetPassword = async (emailToUse) => {
    const emailToSend = emailToUse || email; // Use passed email or state email
    try {
      const response = await forgetPassword(emailToSend);
      console.log(response, "Forget Password API response");
      ToastMsg.success('Otp sent to your email.');
      navigate(`/enter-email-otp?email=${encodeURIComponent(emailToSend)}`);
    } catch (error) {
      console.error('Error sending forget password request:', error);
      ToastMsg.error('Failed to send forget password request. Please try again.'); 
    }
  };

  const handleNavigation = () => {
    if (validateEmail(email)) {
      handleForgetPassword();
    } else {
      console.log('Invalid email');
    }
  };

  return (
    <Container>
      <div className="verify-account-container">
        <div className='flex-row mt-10'>
          <Header />
        </div>
        
        <h2 className="verify-title">Forgot Password</h2>
        <p className="verify-instructions">
          No worries! Enter your email address below and we will send you a code to reset your password.
        </p>
        
        <div className="input-container">
          <img src={emailicon} alt="Email Icon" className="icon-size" />
          <input
            type="text"
            placeholder="Enter your email"
            className="input-field"
            value={email}
            onChange={handleEmailChange}
            onBlur={() => validateEmail(email)}
            disabled={!!hotemailemail} // Disable input if 'hotemailemail' is present
          />
        </div>

        {validationMessage && <p style={{ color: 'red' }}>{validationMessage}</p>}
        
        {/* Fix onClick by passing a function reference, not executing it */}
        {!hotemailemail && (
          <button onClick={handleNavigation} className="verify-button">
            Send Verification Code
          </button>
        )}
      </div>
    </Container>
  );
};

export default VerifyAccount;
