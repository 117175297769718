
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastMsg = {
  success: (msg) => toast.success(msg),
  error: (msg) => toast.error(msg),
  info: (msg) => toast.info(msg),
  warn: (msg) => toast.warn(msg),
};

export default ToastMsg;
