import React, { useState, useEffect } from 'react';
import '../../pages/Login/LoginScreen.css';
import Header from '../common/header';


const PaymentSuccess = ({ transactionNumber, amountPaid, paymentMethod, onConfirm }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    
    const fetchTransactionDetails = () => {
      try {
        
        setTimeout(() => {
          setLoading(false);  
        }, 2000);
      } catch (error) {
        setLoading(false);
        setError('Failed to load transaction details. Please try again.');
      }
    };

    fetchTransactionDetails();
  }, []);

  const handleClick = () => {
    if (onConfirm) {
      onConfirm(); 
    } else {
      alert('Payment success confirmed!');
    }
  };

  if (loading) {
    return (
      <div className="payment-success-container">
        <div className="loading">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="payment-success-container">
        <div className="error-message">{error}</div>
      </div>
    );
  }

  return (
    <div className="payment-success-container">
        <Header />
      <div className="success-icon">
        <div className="circle">
          <div className="checkmark">✔</div>
        </div>
      </div>
      <h2>Ticket Payment Successful</h2>
      <p>Transaction Number: {transactionNumber}</p>
      <div className="payment-details">
        <p>Amount paid <span className="amount">₹{amountPaid}</span></p>
        <p>Payed by <span className="payment-method">{paymentMethod}</span></p>
      </div>
      <button className="confirm-button" onClick={handleClick}>
        Confirm Payment
      </button>
    </div>
  );
};

export default PaymentSuccess;
