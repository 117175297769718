import React, { useEffect } from 'react';
import './SignupScreen.css';
import { useNavigate } from 'react-router-dom';
import paisebnaologo from '../../../assets/images/paisebnaologo.png';
import paisebnaotext from '../../../assets/images/paisebnaotext.png';
import googlebtn from '../../../assets/images/googlebtn.png';
import { GoogleLogin } from 'react-google-login';
import  Container from '../../container';
import GoogleAuth from '../../pages/GoogleAuth';

const SignupScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      navigate('/dashboard');
    }
  }, [navigate]);

  const login = () => {
    navigate('/loginn');
  };

  const register = () => {
    navigate('/register');
  };

 


  return (
    <Container >
    <div className="dashboard-container display-content">
      <img src={paisebnaologo} alt="Logo" className="logo mt-50" />
      <img src={paisebnaotext} alt="Paise Bnao Text Logo" className="logo" />
      <h2 className="subtitle">Hello, welcome!</h2>
      <p className="text">Log in or create an account!</p>
      <button onClick={register} className="register-button">
        Register
      </button>
      <button onClick={login} className="login-button">
        Login
      </button>
      {/* <p className="or-text">or login with</p>
     
      <GoogleAuth/> */}
    </div>
    </Container>
  );
};

export default SignupScreen;
