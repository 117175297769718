import React, { useState } from 'react';

const PaymentForm = () => {
    const [orderId, setOrderId] = useState('');
    const [orderAmount, setOrderAmount] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');
    const [customerPhone, setCustomerPhone] = useState('');

    const handlePayment = () => {
        // Prepare the form data
        const formData = new URLSearchParams();
        formData.append('order_id', orderId);
        formData.append('order_amount', orderAmount);
        formData.append('customer_name', customerName);
        formData.append('customer_email', customerEmail);
        formData.append('customer_phone', customerPhone);

        fetch('/create-order/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-CSRFToken': document.cookie.split('csrftoken=')[1] 
            },
            body: formData
        })
        .then(response => response.json())
        .then(data => {
            if (data.cftoken) {
                window.Cashfree.init({
                    orderId: orderId,
                    orderAmount: orderAmount,
                    tokenData: data.cftoken,
                    orderCurrency: 'INR',
                    appId: process.env.REACT_APP_CASHFREE_APP_ID,  
                    customerEmail: customerEmail,
                    customerPhone: customerPhone
                });
                window.Cashfree.pay();
            }
        })
        .catch(error => console.error('Error:', error));
    };

    return (
        <div>
            <h2>Pay with Cashfree</h2>
            <form id="payment-form">
                <input
                    type="text"
                    placeholder="Order ID"
                    value={orderId}
                    onChange={(e) => setOrderId(e.target.value)}
                    required
                />
                <input
                    type="text"
                    placeholder="Amount"
                    value={orderAmount}
                    onChange={(e) => setOrderAmount(e.target.value)}
                    required
                />
                <input
                    type="text"
                    placeholder="Customer Name"
                    value={customerName}
                    onChange={(e) => setCustomerName(e.target.value)}
                    required
                />
                <input
                    type="email"
                    placeholder="Customer Email"
                    value={customerEmail}
                    onChange={(e) => setCustomerEmail(e.target.value)}
                    required
                />
                <input
                    type="text"
                    placeholder="Customer Phone"
                    value={customerPhone}
                    onChange={(e) => setCustomerPhone(e.target.value)}
                    required
                />
                <button type="button" onClick={handlePayment}>
                    Pay Now
                </button>
            </form>
        </div>
    );
};

export default PaymentForm;
