import React from 'react';
import '../Login/LoginScreen.css'; 
import Header from '../common/header';
import Container from '../../container';

const OopsScreen = () => {
    const handleWatchVideo = () => {
        window.location.href = "/dashboard";
    }

    return (
        <Container>
            <div className="oops-container" style={{ padding: "20px", backgroundColor: "#f7f7f7", borderRadius: "10px", boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)" }}>
                <div className="flex-row mt-10">
                    <Header />
                </div>

                <div className="error-icon-container mt-20" style={{ textAlign: "center", marginBottom: "20px" }}>
                </div>

                <div className="instructions-container" style={{ textAlign: "left", lineHeight: "1.6", marginBottom: "20px" }}>
                    <p className="terms-instructions" style={{ fontSize: "16px", color: "#444", marginBottom: "16px" }}>
                        To participate in PaiseBnao, users must be at least 18 years old and located within eligible regions where the app operates. Only one account is permitted per user. Users are required to provide accurate information during registration.
                    </p>
                    <p className="terms-instructions" style={{ fontSize: "16px", color: "#444", marginBottom: "16px" }}>
                        Users earn money by watching videos provided on the PaiseBnao platform. Each video has a predefined payout, and the time spent watching videos is tracked to ensure compliance. Any attempt to automate or fake video views will result in account suspension.
                    </p>
                    <p className="terms-instructions" style={{ fontSize: "16px", color: "#444", marginBottom: "16px" }}>
                        Earnings can be withdrawn once users reach a minimum balance of Rs. 5000 in their PaiseBnao wallet. Payments are processed through UPI, bank transfer, or digital wallets. Users will be notified of successful transfers, and any processing fees for transactions will be clearly stated.
                    </p>
                    <p className="terms-instructions" style={{ fontSize: "16px", color: "#444", marginBottom: "16px" }}>
                        PaiseBnao reserves the right to suspend or terminate any account found to be violating the platform's rules, such as using bots, manipulating the system, or providing false information. Suspended accounts will forfeit any earned balance.
                    </p>
                    <p className="terms-instructions" style={{ fontSize: "16px", color: "#444", marginBottom: "16px" }}>
                        Before withdrawing earnings, users must pay the platform fee. Without paying this fee, users will not be entitled to withdraw their earnings. Additionally, users must refer at least three friends or family members who successfully log in using the user's referral code. Without completing these referrals, users will be unable to withdraw their earned amount.
                    </p>
                    <p className="terms-instructions" style={{ fontSize: "16px", color: "#444", marginBottom: "16px" }}>
                        If users skip or jump between videos or watch videos irregularly, or if users watch less than one hour a day consistently, their withdrawal amount, membership fee, and platform fee may be forfeited. The user will also not be able to take legal action against the company for violating the rules of the platform.
                    </p>
                    
                    <p className="terms-instructions" style={{ fontSize: "16px", color: "#444", marginBottom: "16px" }}>
                        If the user is facing any issue from the company's side, the user will not be able to take legal action. Any issues will be resolved with mutual understanding between the user and the company. If the company faces financial losses, any earned amount by the user may be forfeited. The company will not take responsibility for any unpaid amounts.
                    </p>
                    <p className="terms-instructions" style={{ fontSize: "16px", color: "#444", marginBottom: "16px" }}>
                        In case of company profit, the company will share some part of the amount earned with the user, based on the user's activity. However, if the company faces financial difficulties, the user will not be able to claim unpaid amounts.
                    </p>
                    <p className="terms-instructions" style={{ fontSize: "16px", color: "#444", marginBottom: "16px" }}>
                        If the user is facing any issue from the company's side, the user will not be able to take legal action. Any issues will be resolved with mutual understanding between the user and the company. If the company faces financial losses, any earned amount by the user may be forfeited, and any membership or platform fees paid by the user will not be refunded. The company will not take responsibility for any unpaid or non-returned amounts.
                    </p>

                    <p className="terms-instructions" style={{ fontSize: "16px", color: "#444", marginBottom: "16px" }}>
                    In the event of adverse circumstances, the company reserves the right to convert the balance in rupees to a coin-based system. The value of these coins will be determined at the sole discretion of the company's authorities. All subsequent payments and withdrawals will be made based on the coin value set by the company.
                    </p>            
                </div>

                {/* Continue Watching Button */}
                <div 
                    onClick={handleWatchVideo} 
                    className="verify-button2" 
                    style={{
                        display: "block",
                        width: "100%",
                        maxWidth: "300px",
                        margin: "0 auto",
                        padding: "10px 20px",
                        color: "#fff",
                        textAlign: "center",
                        borderRadius: "5px",
                        cursor: "pointer",
                        transition: "background-color 0.3s"
                    }}
                    onMouseEnter={(e) => e.target.style.backgroundColor = "#0056b3"}
                    onMouseLeave={(e) => e.target.style.backgroundColor = "#007bff"}
                >
                    Continue Watching Videos
                </div>
            </div>
        </Container>
    );
};

export default OopsScreen;
